import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import MailItem from "./MailItem";
import MainLayout from "../common/MainLayout";
import "../styles/BoardContainer.css";
import AddNewCardButton from "../components/AddNewCardButton";
import AddNewColumnButton from "../components/AddNewColumnButton";
import CardBadge from "../components/CardBadge";
import PopupComponent from "../components/PopupComponent";

function BoardContainerComponent() {
  const [popupState, setPopupState] = useState(false);

  const handleOpenPopup = () => {
    setPopupState(true);
  };

  return (
    <Grid container spacing={1}>
      <div className="boardContainer__board">
        <div className="boardContainer__column">
          <div className="boardContaioner__columnTitle">Main tasks</div>
          <div className="boardContainer__card" onClick={handleOpenPopup}>
            Nick card 1
          </div>
          <div className="boardContainer__card">
            <div className="boardContainer__card--topSection">
              <div className="boardContainer__card--title">Nick Timms</div>
              <div className="boardContainer__card--time">3:22pm</div>
            </div>
            <div className="boardContainer__midSection">
              <span className="boardContainer__card--header">
                How's my driving?
              </span>
              <span className="boardContainer__card--body">
                I'd like to know how we are doing.
              </span>
            </div>
            <div className="boardContainer__bottomSection">
              {/* <CardBadge title={"0/1"} /> */}
              <span className="boardContainer__card--body"></span>
            </div>
          </div>
          <div className="boardContainer__card">Nick card 1</div>
          <div className="boardContainer__card">Nick card 1</div>
          <AddNewCardButton />
        </div>
        <div className="boardContainer__column">
          <div className="boardContaioner__columnTitle">Working on...</div>

          <div className="boardContainer__card">Nick card 1</div>
          <div className="boardContainer__card">Nick card 1</div>
          <div className="boardContainer__card">
            Do Kanban, list, note and dynamic database
          </div>
        </div>
        <div className="boardContainer__column">
          <div className="boardContaioner__columnTitle--empty">Untitled</div>
        </div>
        <AddNewColumnButton />
      </div>

      {/* <Grid container item xs={0} md={2} spacing={3}></Grid>
      <Grid
        className="bodyContainer__container"
        container
        item
        xs={12}
        md={8}
        pacing={3}
      >
        <div className="bodyContainer__boardSection">
          <div className="bodyContainer__title">🚀 hey,. he board (3)</div>
          <MailItem />
          <MailItem />
          <MailItem />
        </div>

        <div className="bodyContainer__boardSection">
          <div className="bodyContainer__title">💣 Marketing board (1)</div>
          <MailItem />
          <MailItem />
          <MailItem />
        </div>
      </Grid>
      <Grid container item xs={0} md={2} spacing={3}></Grid> */}
      <PopupComponent popupState={popupState} setPopupState={setPopupState} />
    </Grid>
  );
}

function BoardContainer(props) {
  return (
    <MainLayout
      bodyProps={{ children: <BoardContainerComponent {...props} /> }}
      headerProps={{}}
      footerProps={{}}
    />
  );
}

export default BoardContainer;
