import React from "react";
import Grid from "@material-ui/core/Grid";
import MailItem from "./MailItem";
import MainLayout from "../common/MainLayout";
import "../styles/SheetContainer.css";
import AddNewCardButton from "../components/AddNewCardButton";
import AddNewColumnButton from "../components/AddNewColumnButton";
import avatardefault from "../assets/img/nick.jpg";

function SheetContainerComponent() {
  return (
    <Grid container spacing={1}>
      <div className="sheetContainer__board">
        <div className="sheetContainer__column" style={{ width: "300px" }}>
          <div className="sheetContaioner__columnTitle">Sheet tasks</div>
          <div className="sheetContainer__card">Finalize kick-off meetings</div>

          <div className="sheetContainer__card">Refine objectives</div>
          <div className="sheetContainer__card">Test version</div>
          <AddNewCardButton />
        </div>
        <div className="sheetContainer__column" style={{ width: "80px" }}>
          <div className="sheetContaioner__columnTitle--small">Owner</div>
          <div className="sheetContainer__cardImageBackground">
            {" "}
            <img
              className="sheetContainer__cardImage"
              src={avatardefault}
              name="avatardefault"
            />
          </div>
          <div className="sheetContainer__cardImageBackground">
            {" "}
            <img
              className="sheetContainer__cardImage"
              src={avatardefault}
              name="avatardefault"
            />
          </div>{" "}
          <div className="sheetContainer__cardImageBackground">
            {" "}
            <img
              className="sheetContainer__cardImage"
              src={avatardefault}
              name="avatardefault"
            />
          </div>
        </div>
        <div className="sheetContainer__column" style={{ width: "120px" }}>
          <div className="sheetContaioner__columnTitle--small">Status</div>
          <div
            className="sheetContainer__card"
            style={{
              backgroundColor: "#3fa65a",
              color: "white",
              textAlign: "center"
            }}
          >
            Done
          </div>
          <div
            className="sheetContainer__card"
            style={{
              backgroundColor: "#f2ba2e",
              color: "white",
              textAlign: "center"
            }}
          >
            Working on
          </div>{" "}
          <div
            className="sheetContainer__card"
            style={{
              backgroundColor: "#de3127",
              color: "white",
              textAlign: "center"
            }}
          >
            Blocker
          </div>
        </div>
        <div className="sheetContainer__column" style={{ width: "200px" }}>
          <div className="sheetContaioner__columnTitle--small">Tags</div>
          <div className="sheetContainer__card">
            <div className="sheetContainer__cardTag">x Launch</div>
          </div>
          <div className="sheetContainer__card">Nick</div>
          <div className="sheetContainer__card">Duda</div>
        </div>
        <div className="sheetContainer__column" style={{ width: "100px" }}>
          <div className="sheetContaioner__columnTitle--small">Due date</div>
          <div className="sheetContainer__card">12th Oct</div>
          <div className="sheetContainer__card">13th Jan</div>
          <div className="sheetContainer__card">3rd Feb</div>
        </div>
        <div className="sheetContainer__column">
          <div
            className="sheetContaioner__columnTitle--empty"
            style={{ width: "200px" }}
          >
            Untitled
          </div>
          <div className="sheetContainer__card"></div>
        </div>
        <AddNewColumnButton />
      </div>
    </Grid>
  );
}

function SheetContainer(props) {
  return (
    <MainLayout
      bodyProps={{ children: <SheetContainerComponent {...props} /> }}
      headerProps={{}}
      footerProps={{}}
    />
  );
}

export default SheetContainer;
