import React from "react";
import Grid from "@material-ui/core/Grid";
import MailItem from "./MailItem";
import MainLayout from "../common/MainLayout";
import "../styles/NoteContainer.css";
import AddNewCardButton from "../components/AddNewCardButton";
import AddNewColumnButton from "../components/AddNewColumnButton";
import CardBadge from "../components/CardBadge";

function NoteContainerComponent() {
  return (
    <Grid container spacing={1}>
      <div className="noteContainer__board">
        <div className="noteContainer__column">
          <div className="noteContaioner__columnTitle--empty">Untitled</div>
          <div className="noteContainer__card">
            Here are some sentences that I am writing
          </div>
          <div className="noteContainer__card">And Im also writing this. </div>
          <div className="noteContainer__card">
            Here are some sentences that I am writing
          </div>
          <div className="noteContainer__card">
            And the reason is that this is because I am writing about how notes
            can be taken inside cards.
          </div>
          <div className="noteContainer__card">Nick card 1</div>
          <AddNewCardButton />
        </div>

        {/* <AddNewColumnButton /> */}
      </div>

      {/* <Grid container item xs={0} md={2} spacing={3}></Grid>
      <Grid
        className="bodyContainer__container"
        container
        item
        xs={12}
        md={8}
        pacing={3}
      >
        <div className="bodyContainer__boardSection">
          <div className="bodyContainer__title">🚀 hey,. he board (3)</div>
          <MailItem />
          <MailItem />
          <MailItem />
        </div>

        <div className="bodyContainer__boardSection">
          <div className="bodyContainer__title">💣 Marketing board (1)</div>
          <MailItem />
          <MailItem />
          <MailItem />
        </div>
      </Grid>
      <Grid container item xs={0} md={2} spacing={3}></Grid> */}
    </Grid>
  );
}

function NoteContainer(props) {
  return (
    <MainLayout
      bodyProps={{ children: <NoteContainerComponent {...props} /> }}
      headerProps={{}}
      footerProps={{}}
    />
  );
}

export default NoteContainer;
