import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";

import MainContainer from "./components/MainContainer";
import BoardContainer from "./components/BoardContainer";
import ListContainer from "./components/ListContainer";
import NoteContainer from "./components/NoteContainer";
import SheetContainer from "./components/SheetContainer";

import BodyContainer from "./components/BodyContainer";

function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route exact path="/">
            <BodyContainer />
          </Route>
          <Route exact path="/boardid">
            <BoardContainer />
          </Route>
          <Route exact path="/listid">
            <ListContainer />
          </Route>
          <Route exact path="/noteid">
            <NoteContainer />
          </Route>
          <Route exact path="/sheetid">
            <SheetContainer />
          </Route>
          {/* <Route
            exact
            path="/"
            render={props => <MainContainer {...props} />}
          ></Route> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
