import React from "react";
import "../styles/IconButtonComponent.css";

function IconButtonComponent({ Icon, title, color }) {
  return (
    <div className="iconButton__container">
      <div style={{ backgroundColor: color }} className="iconButton__icon">
        <Icon className="iconButton__materialicon" />
      </div>
      <div className="iconButton__title">{title}</div>
    </div>
  );
}

export default IconButtonComponent;
