import React from "react";
import Grid from "@material-ui/core/Grid";
import MailItem from "./MailItem";
import MainLayout from "../common/MainLayout";
import "../styles/ListContainer.css";
import AddNewCardButton from "../components/AddNewCardButton";
import AddNewColumnButton from "../components/AddNewColumnButton";
import CardBadge from "../components/CardBadge";

function ListContainerComponent() {
  return (
    <Grid container spacing={1}>
      <div className="listContainer__board">
        <div className="listContainer__column">
          <div className="listContaioner__columnTitle">Nick's Inbox</div>
          <div className="listContainer__card">Nick card 1</div>
          <div className="listContainer__card">
            <div className="listContainer__card--topSection">
              <div className="listContainer__cardTopSectionLeft">
                <div className="listContainer__card--title">Nick Timms</div>
                <span className="listContainer__card--header">
                  How's my driving?
                </span>
                <span className="listContainer__card--body">
                  I'd like to know how we are doing.
                </span>
              </div>

              <div className="listContainer__cardTopSectionRight">
                <div className="listContainer__card--time">3:22pm</div>
              </div>
            </div>
            {/* <div className="listContainer__midSection"></div> */}
            <div className="listContainer__bottomSection">
              {/* <CardBadge title={"0/1"} /> */}
              <span className="listContainer__card--body"></span>
            </div>
          </div>
          <div className="listContainer__card">Nick card 1</div>
          <div className="listContainer__card">Nick card 1</div>
          <AddNewCardButton />
        </div>

        <AddNewColumnButton />
      </div>
    </Grid>
  );
}

function ListContainer(props) {
  return (
    <MainLayout
      bodyProps={{ children: <ListContainerComponent {...props} /> }}
      headerProps={{}}
      footerProps={{}}
    />
  );
}

export default ListContainer;
