import React from "react";
import "../styles/CardBadge.css";

function CardBadge({ icon, title }) {
  return (
    <div className="CardBadge__container">
      <div className="CardBadge__icon">
        <div className="CardBadge__materialicon">{icon}</div>
      </div>
      <div className="CardBadge__title">{title}</div>
    </div>
  );
}

export default CardBadge;
