import React from "react";
import { useHistory } from "react-router-dom";

import "../styles/BoardName.css";

function BoardName({ emoji, title, id, path }) {
  const history = useHistory();

  const selectCategory = () => {
    history.push({ pathname: `/${path}`, state: { id } });
  };

  return (
    <div className="BoardName__container" onClick={selectCategory}>
      <div className="BoardName__icon">
        <div className="BoardName__materialicon">{emoji}</div>
      </div>
      <div>{title}</div>
    </div>
  );
}

export default BoardName;
