import React from "react";
import AddIcon from "@material-ui/icons/Add";
import "../styles/AddNewButton.css";

function AddNewButton() {
  return (
    <div className="addNewButton">
      <AddIcon />
    </div>
  );
}

export default AddNewButton;
