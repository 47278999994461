import React from "react";
import "../styles/SidebarIcon.css";
import InboxIcon from "@material-ui/icons/Inbox";

function SidebarIcon({ Icon, title, color }) {
  return (
    <div className="sidebarIcon__container">
      <div style={{ backgroundColor: color }} className="sidebarIcon__icon">
        <Icon className="sidebarIcon__materialicon" />
      </div>
      <div>{title}</div>
    </div>
  );
}

export default SidebarIcon;
