import React from "react";
import AddIcon from "@material-ui/icons/Add";
import "../styles/AddNewButton.css";

function AddNewCardButton() {
  return (
    <div className="addNewCardButton">
      <AddIcon />
    </div>
  );
}

export default AddNewCardButton;
