import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import SendIcon from "@material-ui/icons/Send";
import DeleteIcon from "@material-ui/icons/Delete";
import GroupIcon from "@material-ui/icons/Group";
import ArchiveIcon from "@material-ui/icons/Archive";
import DraftsIcon from "@material-ui/icons/Drafts";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import SidebarIcon from "../components/SidebarIcon";
import InboxActions from "../components/InboxActions";
import BoardName from "../components/BoardName";
import "../styles/MainContainer.css";
import SidebarCategory from "../components/SidebarCategory";
import AddNewButton from "../components/AddNewButton";
import HomeIcon from "@material-ui/icons/Home";
import "../styles/MainLayout.css";

const drawerWidth = 220;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    boxShadow: "none",
    position: "inherit"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 0
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#ffffff!important",
    borderRight: "none!important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    backgroundColor: "#ffffff!important",
    borderRight: "none!important",
    width: "0px"
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(8) + 1
    // }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#ffffff!important",

    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#ffffff!important",

    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#ffffff!important"
  },
  navigation: {
    display: "flex!important",
    alignItems: "center!important",
    color: "var(--maingrey-color)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ],
    fontSize: "0.9rem",
    fontWeight: "400"
  },
  homeIcon: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px"
  },
  navLink: {
    "&:hover": {
      color: "var(--primary-color)",
      cursor: "pointer"
    }
  },

  navSeparator: {
    padding: "0px 10px"
  }
}));

const HeaderComponent = props => {
  const classes = useStyles();
  const { handleDrawerOpen, open } = props;

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}
    >
      <Toolbar className="mainContainer__toolbar">
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.navigation} noWrap>
          <span className={classes.navLink}>
            <HomeIcon className={classes.homeIcon} />
          </span>{" "}
          <span className={classes.navSeparator}>/</span>
          <span className={classes.navLink}>Nick's Inbox</span>
          <span className={classes.navSeparator}>/</span>
          <span className={classes.navLink}>Unread</span>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const BodyComponent = props => {
  const classes = useStyles();
  return <div className={classes.body}>{props.children}</div>;
};

const NavBarComponent = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { handleDrawerClose, open } = props;

  return (
    <Fragment>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <SidebarCategory title={"favorite"} />

        <BoardName
          emoji={"📨"}
          color={""}
          title={"Nick's Inbox"}
          path={"boardid"}
        />
        <BoardName
          emoji={"🚨"}
          color={""}
          title={"Support Inbox"}
          path={"listid"}
        />

        {/* <SidebarIcon Icon={InboxIcon} color={"#"} title={"Nick's Inbox"} /> */}
        {/* <SidebarIcon Icon={InboxIcon} color={"#"} title={"Support@ Inbox"} /> */}

        {/* <div style={{ marginTop: "25px" }}></div> */}
        {/* 
        <InboxActions Icon={ArchiveIcon} color={""} title={"Archived"} />
        <InboxActions Icon={DraftsIcon} color={""} title={"Drafts"} />
        <InboxActions Icon={SendIcon} color={""} title={"Sent"} />
        <InboxActions Icon={DeleteIcon} color={""} title={"Trash"} /> */}

        <div style={{ marginTop: "35px" }}></div>

        <SidebarCategory title={"spaces"} />
        <BoardName
          emoji={"🚀"}
          color={""}
          title={"Sales Inbox (3)"}
          path={"noteid"}
        />
        <BoardName
          emoji={"💣"}
          color={""}
          title={"Marketing board"}
          path={"sheetid"}
        />
        <BoardName
          emoji={"👩‍👧"}
          color={""}
          title={"Family info"}
          path={"boardid"}
        />
        <BoardName
          emoji={"💵"}
          color={""}
          title={"Fundraising notes"}
          path={"boardid"}
        />
        <BoardName
          emoji={"🏖️"}
          color={""}
          title={"Holiday plans"}
          path={"boardid"}
        />
        <AddNewButton />

        <div style={{ marginTop: "35px" }}></div>

        <SidebarCategory title={"shortcuts"} />

        <SidebarIcon
          Icon={AssignmentTurnedInIcon}
          color={"#"}
          title={"Assigned to me (1)"}
        />
        <SidebarIcon Icon={GroupIcon} color={"#"} title={"Shared with me"} />
      </Drawer>
    </Fragment>
  );
};

export default function MainLayout(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Grid container className={classes.root} direction="column">
      <Grid item container xs={12}>
        <HeaderComponent
          {...props.headerProps}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
        />
      </Grid>
      <Grid item container>
        <Grid item xs={2}>
          <NavBarComponent
            {...props.headerProps}
            handleDrawerClose={handleDrawerClose}
            open={open}
          />
        </Grid>
        <Grid item xs={10}>
          <BodyComponent {...props.bodyProps} />
        </Grid>
      </Grid>
    </Grid>
  );
}
