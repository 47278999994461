import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import SidebarIcon from "../components/SidebarIcon";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import VisibilityIcon from "@material-ui/icons/Visibility";

import "../styles/PopupComponent.css";
import IconButtonComponent from "./IconButtonComponent";

function PopupComponent(props) {
  const { popupState, setPopupState } = props;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="popupComponent__modal"
        open={popupState}
        onClose={() => {
          setPopupState(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={popupState}>
          <Grid className="popupComponent__paper">
            <Grid className="popupComponent__topNav">
              <Grid className="popupComponent__topNav--left">
                <IconButtonComponent
                  Icon={OpenInNewIcon}
                  color={"#"}
                  title={"Open as page"}
                />
              </Grid>
              <Grid className="popupComponent__topNav--right">
                <IconButtonComponent
                  Icon={VisibilityIcon}
                  color={"#"}
                  title={"Anyone (public)"}
                />
                <IconButtonComponent
                  Icon={MoreHorizIcon}
                  color={"#"}
                  title={""}
                  style={{ paddingRight: "0px!important" }}
                />
              </Grid>
            </Grid>
            <Grid className=""></Grid>
            <div>EMAILS</div>

            <div>
              DO areas like Notion where we have files, 'Untitled' section also.
            </div>
          </Grid>
        </Fade>
      </Modal>
    </div>
  );
}

export default PopupComponent;
