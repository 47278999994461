import React from "react";
import Grid from "@material-ui/core/Grid";
import MailItem from "./MailItem";
import MainLayout from "../common/MainLayout";
import "../styles/BodyContainer.css";

function BodyContainerComponent() {

  return (
    <Grid container spacing={1}>
      <Grid container item xs={0} md={2} spacing={3}></Grid>
      <Grid
        className="bodyContainer__container"
        container
        item
        xs={12}
        md={8}
        pacing={3}
      >
        <div className="bodyContainer__boardSection">
          <div className="bodyContainer__title">🚀 Sales board (3)</div>
          <MailItem />
          <MailItem />
          <MailItem />
        </div>

        <div className="bodyContainer__boardSection">
          <div className="bodyContainer__title">💣 Marketing board (1)</div>
          <MailItem />
          <MailItem />
          <MailItem />
        </div>
      </Grid>
      <Grid container item xs={0} md={2} spacing={3}></Grid>
    </Grid>
  );
}

function BodyContainer(props) {
  return (
    <MainLayout
      bodyProps={{ children: <BodyContainerComponent {...props} /> }}
      headerProps={{}}
      footerProps={{}}
    />
  );
};

export default BodyContainer;
