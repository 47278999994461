import React from "react";
import "../../src/styles/MailItem.css";

import avatardefault from "../assets/img/nick.jpg";

function MailItem() {
  return (
    <div className="mailItem__container">
      <div className="mailItem__left">
        <div className="mailItem__avatar">
          <img
            src={avatardefault}
            name="avatardefault"
            // style={{ height: "60px", marginTop: "10px", marginBottom: "20px" }}
          />
        </div>
        <div>
          <div className="mailItem__cardTitle">Nick Timms</div>
          <div className="mailItem__cardBody">
            <div>Title of post</div>
            <div className="mailItem__cardText">
              Here is the main body of the post.
            </div>
          </div>
        </div>
      </div>

      <div className="mailItem__right">
        <div className="mailItem__timestamp">7:05pm</div>
      </div>
    </div>
  );
}

export default MailItem;
