import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MainLayout from "../common/MainLayout";

const drawerWidth = 220;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    boxShadow: "none"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#ffffff!important",
    borderRight: "none!important",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    backgroundColor: "#ffffff!important",
    borderRight: "none!important",
    width: "0px"
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(8) + 1
    // }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#ffffff!important",

    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#ffffff!important",

    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#ffffff!important"
  },
  navigation: {
    color: "var(--primary-color)",
    fontSize: "0.9rem",
    fontWeight: "400",
    backgroundColor: "#ffffff!important"
  },
  navLink: {
    padding: "8px 10px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#ffffff",
      cursor: "pointer"
    }
  }
}));

function MainContainerComponent(props) {
  console.log("Hi");
  return <div></div>;
}

function MainContainer(props) {
  return (
    <MainLayout
      bodyProps={{ children: <MainContainerComponent {...props} /> }}
      headerProps={{}}
      footerProps={{}}
    />
  );
}

export default MainContainer;
